import { toNumber } from 'lodash'

import { secondsFormatter } from '@/helpers'

export const NumberFormat = new Intl.NumberFormat('ru-RU')
export const CurrencyPercentFormat = new Intl.NumberFormat('ru-RU', { maximumFractionDigits: 2, minimumFractionDigits: 2 })

export function isNumber(val?: string | number | null) {
  return !isNaN(parseFloat(val as string)) && isFinite(val as number)
}

export function nFormatter(num: number | string, digits: number, minimumFractionDigits: number = 0) {
  if (!isNumber(num)) {
    return NaN
  }

  const numSign = Math.sign(num as number)
  const numWithoutSign = Math.abs(num as number)

  const si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' }
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  let i: number
  for (i = si.length - 1; i > 0; i--) {
    if (numWithoutSign >= si[i].value) {
      break
    }
  }
  const formatter = new Intl.NumberFormat('ru-RU', { maximumFractionDigits: digits, minimumFractionDigits })
  const value = Number(((numWithoutSign / si[i].value) * numSign).toFixed(digits))
  const formattedValue = formatter.format(value)

  return formattedValue.replace(rx, '$1').replace(/,/g, '.') + si[i].symbol
}

export function decimalLengthCheck(value: string | number, lengthToCheck: number, numberCheck: boolean = true): boolean {
  const isNaNValue = numberCheck ? isNaN(toNumber(value)) : false
  let hasLongDecimal: boolean = false

  if (!isNaNValue) {
    const splitValue: string[] = String(value).split('.')
    hasLongDecimal =
      splitValue.length === 2 ? splitValue[0] === '0' && splitValue[1].length > lengthToCheck && +splitValue[1].slice(0, lengthToCheck) === 0 : false
  }

  return hasLongDecimal
}

export function formatValueToLocaleString(value: string | number, type: TValueType | 'Seconds', fractionDigits?: number | null): string | number {
  if (['Seconds'].includes(type)) {
    return secondsFormatter(Number(value), true)
  }

  const isNumberType: boolean = ['Number'].includes(type)
  const isCashType: boolean = ['Cash'].includes(type)
  const valueFormatter = isNumber(fractionDigits) ? getRoundFormat(fractionDigits as number) : isNumberType ? NumberFormat : CurrencyPercentFormat
  const isValidNumberType = valueFormatter.formatToParts(value as number)[0].type

  if (isValidNumberType !== 'nan') {
    const isCurrencyPercent: boolean = ['Percentage', 'Rate', 'Cash'].includes(type)
    const metricFormat = ['Percentage', 'Rate'].includes(type) ? '%' : isCashType ? '$' : ''
    const lengthToCheck = fractionDigits ? fractionDigits : isCurrencyPercent ? 2 : 3
    const hasLongDecimal: boolean = isNumberType || isCurrencyPercent ? decimalLengthCheck(value, lengthToCheck, false) : false
    const formattedValue: string = isCashType
      ? `${metricFormat}${valueFormatter.format(value as number)}`
      : `${valueFormatter.format(value as number)}${metricFormat}`
    const approximatedValue: string = isCashType ? `≈${metricFormat}0` : `≈0${metricFormat}`
    const fullValue: string = hasLongDecimal ? approximatedValue : formattedValue

    return fullValue.replace(/,/g, '.')
  } else {
    return value
  }
}

export function getRoundFormat(fractionDigits: number): Intl.NumberFormat {
  return new Intl.NumberFormat('ru-RU', { minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits })
}

export function calculateDifference(curValue: number, prevValue: number, valueType: TValueType = 'Number'): number {
  if (prevValue === curValue) return 0
  if (prevValue === 0) return 100
  return valueType === 'Percentage' ? (curValue / prevValue) * 100 - 100 : (curValue / prevValue - 1) * 100
}

export function getNumberType(number: number): 'positive' | 'negative' | 'neutral' {
  if (number > 0) {
    return 'positive'
  } else if (number < 0) {
    return 'negative'
  }

  return 'neutral'
}
